<template>
  <div class="mx-auto px-8 max-w-screen-xl w-full">
    <div
      class="flex justify-between rounded bg-gray-200 md:pl-6 mt-8 flex-col
        md:flex-row py-2"
      :class="{ 'border-2 border-green-500': nextStepsComplete }">
      <h3 class="text-base font-sans font-bold text-primary self-center
      md:self-start pt-2 pb-4 md:pb-2">
        Get Started with Reunacy! 🎉
      </h3>
      <div class="flex flex-col md:flex-row px-4 md:my-0 py-2 bg-transparent md:bg-gray-300 rounded
      border-t md:border-t-0 border-b md:border-b-0 border-gray-400">
        <div class="flex text-gray-700 font-bold text-base">
          <BannerIcon :complete="!!bannerType.option1.value" />
          <h5
            :class="{ 'text-green-500': !!bannerType.option1.value }"
            class="mr-2 py-3 md:py-0 font-bold">{{ bannerType.option1.label }}</h5>
        </div>
        <div class="flex text-gray-700 font-bold text-base ">
          <BannerIcon :complete="!!bannerType.option2.value" />
          <h5
            :class="{ 'text-green-500': !!bannerType.option2.value }"
            class="mr-2 py-3 md:py-0 font-bold">{{ bannerType.option2.label }}</h5>
        </div>
        <div class="flex text-gray-700 font-bold text-base">
          <BannerIcon :complete="!!bannerType.option3.value" />
          <h5
            :class="{ 'text-green-500': !!bannerType.option3.value }"
            class="mr-2 py-3 md:py-0 font-bold">{{ bannerType.option3.label }}</h5>
        </div>
      </div>
      <button
        class="font-bold text-primary text-base font-sans px-6 pt-4 pb-2 md:pt-0 md:pb-0"
        @click="hideWelcomeBanner">Close</button>
    </div>
  </div>
</template>

<script>
import BannerIcon from '@/components/BannerIcon.vue';

export default {
  name: 'WelcomeBanner',
  components: {
    BannerIcon,
  },
  props: {
    /**
     * includes what should be displayed in banner
     */
    bannerType: {
      default: () => ({
        option1: { label: '', value: '' },
        option2: { label: '', value: '' },
        option3: { label: '', value: '' },
      }),
    },
    /**
     * action to prevent banner from showing again
     */
    hideBanner: {
      type: Function,
      required: true,
    },
    /**
     * confirms is all steps are done
     */
    nextStepsComplete: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    hideWelcomeBanner() {
      this.hideBanner();
    },
  },
};
</script>
